import * as r from 'ramda';
import getLanguages from './getLanguages';

const getLanguage = r.pipe(
  r.converge(
    (language, languages) => r.find(r.propEq('full', language), languages),
    [r.prop('currentLanguage'), getLanguages],
  ),
  r.prop('lang'),
);

export default getLanguage;
