import * as r from 'ramda';
import { Colorway } from '@uds/theme-utils';

// This is pretty much a noop function
// it ipurely in case the values
// from the API differ from our internals
// when they do we will be ready!
function colorwayStringToEnum(colorwayRaw) {
  return r.defaultTo(Colorway.LIGHT)(Colorway[colorwayRaw]);
}

export default function colorwayAdapter(schema) {
  return r.evolve({
    props: {
      colorway: colorwayStringToEnum,
    },
  })(schema);
}
