import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Type } from 'domain/constants/ribbons';
import { VisibilitySet } from 'core/ribbons/primitives';
import { Ribbons } from 'core/page';

type Context = {
  [key: string]: string;
};
export type SetVisibilityContext = (visibilitySets?: VisibilitySet) => void;

export const VisibilitySetsContext = createContext<Context | undefined>(undefined);
export const VisibilitySetsActionContext = createContext<SetVisibilityContext | undefined>(undefined);

export const useVisibilitySets = () => {
  const context = useContext(VisibilitySetsContext);
  return context;
};

export const useVisibilitySetsAction = () => {
  const context = useContext(VisibilitySetsActionContext);
  return context;
};

export const getInitialVisibilitySets = ({ ribbons = [] }: { ribbons?: Ribbons }) => {
  const metaRibbon = ribbons.find((ribbon) => ribbon.type === Type.META);
  const initialVisibilitySets = metaRibbon?.props?.initialVisibilitySets;

  const entries = new Map(initialVisibilitySets ? initialVisibilitySets : []);
  return Object.fromEntries(entries);
};

export const testVisibilitySet = ({
  activeVisibilitySets = {},
  visibilitySet = [],
}: {
  activeVisibilitySets?: Object;
  visibilitySet?: VisibilitySet;
}) => {
  return (
    !visibilitySet ||
    visibilitySet.every((set) => {
      return Object.entries(activeVisibilitySets).some((activeSet) => {
        return set.toString() === activeSet.toString();
      });
    })
  );
};

export const useIsVisibilitySetActive = () => {
  const activeVisibilitySets = useVisibilitySets();
  return (visibilitySet: VisibilitySet) => testVisibilitySet({ activeVisibilitySets, visibilitySet });
};

export const VisibilitySetsProvider = ({ children, ribbons }: { children: ReactNode; ribbons?: Ribbons }) => {
  const [currentVisibilitySets, setCurrentVisibilitySets] = useState({});

  useEffect(() => {
    const initialVisibilitySets = getInitialVisibilitySets({ ribbons });
    setCurrentVisibilitySets(initialVisibilitySets);
  }, [ribbons, setCurrentVisibilitySets]);

  const setVisibility = (visibilitySets: VisibilitySet) => {
    const entries = new Map(visibilitySets ?? []);
    setCurrentVisibilitySets((state) => ({
      ...state,
      ...Object.fromEntries(entries),
    }));
  };

  return (
    <VisibilitySetsContext.Provider value={currentVisibilitySets}>
      <VisibilitySetsActionContext.Provider value={setVisibility}>{children}</VisibilitySetsActionContext.Provider>
    </VisibilitySetsContext.Provider>
  );
};
