import { Type as RibbonType, Type } from 'domain/constants/ribbons';
import * as r from 'ramda';
import { Ribbon, Ribbons } from 'core/page';

import colorwayAdaptor from '../ribbons/adapters/colorwayAdapter';

const getPropsWithColor = r.pipe(colorwayAdaptor, r.prop('props')) as (ribbon: Ribbon) => any;

export const componentAdapterMap = {
  [RibbonType.CONTACT_FORM]: getPropsWithColor,
  [RibbonType.THIRD_PARTY]: getPropsWithColor,
  [RibbonType.ADS]: getPropsWithColor,
  [RibbonType.ADVOCATE_TRUST_PILOT]: getPropsWithColor,
  [RibbonType.AGGREGATE_RATING_TRUST_PILOT]: getPropsWithColor,
  [RibbonType.ASPECTS]: getPropsWithColor,
  [RibbonType.BILLBOARD_GENERIC]: getPropsWithColor,
  [RibbonType.BILLBOARD_PRODUCT]: getPropsWithColor,
  [RibbonType.BILLBOARD_DOMAIN]: getPropsWithColor,
  [RibbonType.BROADCAST]: getPropsWithColor,
  [RibbonType.CHANNELS]: getPropsWithColor,
  [RibbonType.CENTERFOLD]: getPropsWithColor,
  [RibbonType.CUSTOMER_CASE_STUDY]: getPropsWithColor,
  [RibbonType.DOMAIN_SEARCH_RESULT_HERO]: getPropsWithColor,
  [RibbonType.DOMAIN_SEARCH_RESULTS]: getPropsWithColor,
  [RibbonType.DOMAIN_SEARCH_START]: getPropsWithColor,
  [RibbonType.DOMAIN_SEARCH_START_STRIP]: getPropsWithColor,
  [RibbonType.WEBSITE_REPORT_START]: getPropsWithColor,
  [RibbonType.DOMAIN_PROMO]: getPropsWithColor,
  [RibbonType.ELABORATE]: getPropsWithColor,
  [RibbonType.FILMSTRIP]: getPropsWithColor,
  [RibbonType.FREE_SPEECH]: getPropsWithColor,
  [RibbonType.INLINE_PRIVACY_MANAGER]: getPropsWithColor,
  [RibbonType.INTRO]: getPropsWithColor,
  [RibbonType.LEGAL]: getPropsWithColor,
  [RibbonType.LEGAL_DOCUMENT]: getPropsWithColor,
  [RibbonType.MESSAGE_STRIP]: getPropsWithColor,
  [RibbonType.META]: r.prop('props'),
  [RibbonType.MULTI_COMPARISON]: getPropsWithColor,
  [RibbonType.PILLARS]: getPropsWithColor,
  [RibbonType.PILLARS_RECUMBENT]: getPropsWithColor,
  [RibbonType.POSTER]: getPropsWithColor,
  [RibbonType.PRODUCT_CARDS]: getPropsWithColor,
  [RibbonType.SALESBOARD_CLASSIC]: getPropsWithColor,
  [RibbonType.SHOWCASE]: getPropsWithColor,
  [RibbonType.TABLE]: getPropsWithColor,
  [RibbonType.TERMINATE_FOLD]: r.prop('props'),
  [RibbonType.TIERS]: getPropsWithColor,
} as {
  [key: string]: (ribbon: Ribbon) => {
    [key: string]: any;
  };
};

// This function needs to be static instead of a dynamic lookup as webpack
// is unable to statically resolve these otherwise. By doing it like this
// webpack is able to look into each of these and decide how downstream c
// chunks should be created.
// For example if both Ads and Billboard need package A, then that
// should be in its own chunk so that extra isn't needed when getting
// either ribbon.
export function importRibbon(ribbonType: Type) {
  // TODO: Resolve why linting thinks this is good indentation
  switch (ribbonType) {
    case RibbonType.CONTACT_FORM:
      return import(
        /* webpackChunkName: "contact-form" */
        '../ribbons/contact-form'
      );
    case RibbonType.ADS:
      return import(
        /* webpackChunkName: "ads" */
        '../ribbons/ads'
      );
    case RibbonType.THIRD_PARTY:
      return import(
        /* webpackChunkName: "third-party" */
        '../ribbons/third-party'
      );
    case RibbonType.ADVOCATE_TRUST_PILOT:
      return import(
        /* webpackChunkName: "advocate-trustpilot" */
        '../ribbons/advocate-trustpilot'
      );
    case RibbonType.AGGREGATE_RATING_TRUST_PILOT:
      return import(
        /* webpackChunkName: "aggregate-trustpilot" */
        '../ribbons/aggregate-rating-trustpilot'
      );
    case RibbonType.ASPECTS:
      return import(
        /* webpackChunkName: "aspects" */
        '../ribbons/aspects'
      );
    case RibbonType.BILLBOARD_GENERIC:
      return import(
        /* webpackChunkName: "billboard-generic" */
        '../ribbons/billboard'
      );
    case RibbonType.BILLBOARD_PRODUCT:
      return import(
        /* webpackChunkName: "billboard-product" */
        '../ribbons/billboard-product'
      );
    case RibbonType.BILLBOARD_DOMAIN:
      return import(
        /* webpackChunkName: "billboard-domain" */
        '../ribbons/billboard-domain'
      );
    case RibbonType.BROADCAST:
      return import(
        /* webpackChunkName: "broadcast" */
        '../ribbons/broadcast'
      );
    case RibbonType.CENTERFOLD:
      return import(
        /* webpackChunkName: "centerfold" */
        '../ribbons/centerfold'
      );
    case RibbonType.CHANNELS:
      return import(
        /* webpackChunkName: "channels" */
        '../ribbons/channels'
      );
    case RibbonType.CUSTOMER_CASE_STUDY:
      return import(
        /* webpackChunkName: "customer-case-study" */
        '../ribbons/customer-case-study'
      );
    case RibbonType.DOMAIN_SEARCH_RESULT_HERO:
      return import(
        /* webpackChunkName: "domain-search-result-hero" */
        '../ribbons/domain-search-result-hero'
      );
    case RibbonType.DOMAIN_SEARCH_START:
      return import(
        /* webpackChunkName: "domain-search-start" */
        '../ribbons/domain-search-input'
      );
    case RibbonType.DOMAIN_SEARCH_RESULTS:
      return import(
        /* webpackChunkName: "domain-search-results" */
        '../ribbons/domain-search-results'
      );
    case RibbonType.DOMAIN_SEARCH_START_STRIP:
      return import(
        /* webpackChunkName: "domain-search-start-strip" */
        '../ribbons/domain-search-start-strip'
      );
    case RibbonType.WEBSITE_REPORT_START:
      return import(
        /* webpackChunkName: "website-report-start" */
        '../ribbons/website-report-start'
      );
    case RibbonType.DOMAIN_PROMO:
      return import(
        /* webpackChunkName: "domain-promo" */
        '../ribbons/domain-promo'
      );
    case RibbonType.ELABORATE:
      return import(
        /* webpackChunkName: "elaborate" */
        '../ribbons/elaborate'
      );
    case RibbonType.INTRO:
      return import(
        /* webpackChunkName: "intro" */
        '../ribbons/info'
      );
    case RibbonType.FILMSTRIP:
      return import(
        /* webpackChunkName: "filmstrip" */
        '../ribbons/filmstrip'
      );
    case RibbonType.FREE_SPEECH:
      return import(
        /* webpackChunkName: "free-speech" */
        '../ribbons/free-speech'
      );
    case RibbonType.INLINE_PRIVACY_MANAGER:
      return import(
        /* webpackChunkName: "inline-privacy-manager" */
        '../ribbons/inline-privacy'
      );
    case RibbonType.LEGAL:
      return import(
        /* webpackChunkName: "legal" */
        '../ribbons/legal'
      );
    case RibbonType.LEGAL_DOCUMENT:
      return import(
        /* webpackChunkName: "legal-document" */
        '../ribbons/legal-document'
      );
    case RibbonType.MESSAGE_STRIP:
      return import(
        /* webpackChunkName: "message-strip" */
        '../ribbons/message-strip'
      );
    case RibbonType.META:
      return import(
        /* webpackChunkName: "meta" */
        '../ribbons/MetaRibbon'
      );
    case RibbonType.MULTI_COMPARISON:
      return import(
        /* webpackChunkName: "multi-comparison" */
        '../ribbons/multi-comparison'
      );
    case RibbonType.PILLARS:
      return import(
        /* webpackChunkName: "pillars" */
        '../ribbons/pillars'
      );
    case RibbonType.PILLARS_RECUMBENT:
      return import(
        /* webpackChunkName: "pillars-recumbent" */
        '../ribbons/pillars-recumbent'
      );
    case RibbonType.POSTER:
      return import(
        /* webpackChunkName: "poster" */
        '../ribbons/poster'
      );
    case RibbonType.PRODUCT_CARDS:
      return import(
        /* webpackChunkName: "product-cards" */
        '../ribbons/product-cards'
      );
    case RibbonType.SALESBOARD_CLASSIC:
      return import(
        /* webpackChunkName: "salesboard" */
        '../ribbons/salesboard'
      );
    case RibbonType.SHOWCASE:
      return import(
        /* webpackChunkName: "showcase" */
        '../ribbons/showcase'
      );
    case RibbonType.TABLE:
      return import(
        /* webpackChunkName: "table" */
        '../ribbons/table'
      );
    case RibbonType.TERMINATE_FOLD:
      return Promise.resolve({ default: () => null });
    case RibbonType.TIERS:
      return import(
        /* webpackChunkName: "tiers" */
        '../ribbons/tiers'
      );
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown ribbon type ${ribbonType}`);
      return Promise.resolve({ default: () => null });
  }
}

export const isSkippedRibbon = (ribbon: Ribbon) => {
  if (r.path(['props', 'skipped'], ribbon)) {
    // eslint-disable-next-line no-console
    console.warn(`${ribbon.type} ribbon skipped: ${ribbon.props.payload}`);
    return true;
  }
  return false;
};

export const isUnknownRibbonType = (ribbon: Ribbon) => {
  if (r.path(['props', 'unknown'], ribbon)) {
    // eslint-disable-next-line no-console
    console.warn(`Unknown ribbon type ${ribbon.type}`);
    return true;
  }
  return false;
};

const isTrustPilot = (ribbon: Ribbon) => {
  return [RibbonType.ADVOCATE_TRUST_PILOT, RibbonType.AGGREGATE_RATING_TRUST_PILOT].indexOf(ribbon.type) > -1;
};

const hasLowScore = (ribbon: Ribbon) => {
  return ribbon.props.trustScore < 3;
};

// TrustPilot ribbons should not be rendered when the trust score drops below 3
export const isTrustscoreViable = (ribbon: Ribbon) => {
  return !(isTrustPilot(ribbon) && hasLowScore(ribbon));
};

// @ts-ignore: error after upgrading to ux-configs
export const isHiddenRibbon = r.either(r.path(['props', 'hidden']), r.complement(isTrustscoreViable));

export const isDuoLayoutStart = r.pathEq(['props', 'layout', 'startDuoLayout'], true);
export const isDuoLayoutEnd = r.pathEq(['props', 'layout', 'endDuoLayout'], true);

export const getPreviousRibbon = (idx: number, allRibbons: Ribbons) => {
  for (let i = idx - 1; i >= 0; i--) {
    if (!isHiddenRibbon(allRibbons[i])) {
      return allRibbons[i];
    }
  }
};
export const getNextRibbon = (idx: number, allRibbons: Ribbons) => {
  for (let i = idx + 1; i < allRibbons.length; i++) {
    if (!isHiddenRibbon(allRibbons[i])) {
      return allRibbons[i];
    }
  }
};

export const getRibbonAdapter = (ribbonType: string) => componentAdapterMap[ribbonType];

export const isSupportedRibbon = (ribbonType: string) =>
  Object.prototype.hasOwnProperty.call(componentAdapterMap, ribbonType);
