import React from 'react';
import { IdpProvider } from '@ux/auth';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HeadingRoot, HeadingSection } from '@team-griffin/react-heading-section';
import { BreakpointRoot } from '@ux/responsive';
import { useTheme, useAssets } from '@ux/whitelabel';
import { buildCssVars, ThemeProvider } from '@uds/theme-utils';
import TypographyImporter from '@uds/typography-importer';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { useLanguage, useMessages } from '@ux/language';
import { OrchestrationProvider } from '@ux/orchestration';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ModalRouter } from 'react-modal-router';

import { PageContextProvider } from './PageContext';
import App from './App';

const apps = ['brand', 'usf', 'bks', 'dst'];
const scopes = ['brand', 'usf', 'bks', 'cookie', 'dst', 'ux', 'country'];

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      retryOnMount: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: (count: number, err: { statusCode?: string | number; code?: string | number }) => {
        if (count >= 3 || `${err?.statusCode}`.startsWith('4') || `${err?.code}`.startsWith('4')) {
          return false;
        }
        return true;
      },
    },
  },
});

const DisplayProviders = () => {
  const theme = useTheme();
  const themeVars = buildCssVars(theme);
  const favicon = useAssets()?.favicon;
  const locale = useLanguage().data;
  const messages = useMessages().data;

  return (
    <ThemeProvider theme={theme}>
      <TypographyImporter typography={theme.typography} />
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <style>{themeVars}</style>
        <meta name="theme-color" content={theme.palette.primary} />
        <style>
          {`
              .scroller {
                scrollbar-color: transparent transparent;
              }
              .scroller::-webkit-scrollbar {
                display: none;
              }
              .fresnel-at-xs {
                z-index: 1000;
              }
          `}
        </style>
      </Helmet>
      <IntlProvider locale={locale as string} messages={messages}>
        <BreakpointRoot>
          <HeadingRoot>
            <HeadingSection>
              <PageContextProvider>
                <App />
              </PageContextProvider>
            </HeadingSection>
          </HeadingRoot>
        </BreakpointRoot>
      </IntlProvider>
    </ThemeProvider>
  );
};

const AppProviders = () => {
  return (
    <ModalRouter>
      <QueryClientProvider client={queryCache}>
        <BrowserRouter>
          <IdpProvider>
            <OrchestrationProvider apps={apps} scopes={scopes}>
              <DisplayProviders />
            </OrchestrationProvider>
          </IdpProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ModalRouter>
  );
};

export default AppProviders;
