import { PrivacyModal } from '@uds/privacy-manager';
import { useTealiumCategories, useUrl } from '@ux/whitelabel';

export default function PrivacyPolicyModal() {
  const privacyPolicyUrl = useUrl('legal.privacy');
  const categories = useTealiumCategories();
  return (
    <PrivacyModal
      privacyPolicyUrl={privacyPolicyUrl ?? ''}
      order={categories ?? []}
      allowlist={[
        'brand_id',
        'market',
        'auth_idp',
        'info_idp',
        'ux-language-preferred',
        'segmentation_ongoing',
        'segmentation_seed',
        'segmentation_initial',
      ]}
    />
  );
}
