import { BreakpointProps } from '@ux/responsive';

const iterateReturnValue = (value: any, lastValidValue: any, supportNull: boolean) => {
  if (supportNull) {
    if (value === null || value) {
      return value;
    }
    return lastValidValue;
  }
  return value || lastValidValue;
};

// We want to find the item that corresponds
// to the current active breakpoint size.
// However, we can't just do a direct lookup
// as that entry might be `undefined` in which
// case we'd want to waterfall until we find it.
export default function findResponsiveItem(sizes: BreakpointProps, items: any, options: any = {}) {
  if (!Array.isArray(items)) {
    if (options.supportNull) {
      return items == null || items ? items : undefined;
    }
    return items || undefined;
  }
  let value = iterateReturnValue(items[0], undefined, options.supportNull);
  if (sizes.isMinSm) {
    value = iterateReturnValue(items[1], value, options.supportNull);
  }
  if (sizes.isMinLg) {
    value = iterateReturnValue(items[2], value, options.supportNull);
  }
  return value;
}
