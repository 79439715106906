import { useQuery, UseQueryOptions } from 'react-query';
import { useLocation } from 'react-router';
import { EXPERIENCE } from 'domain/constants/queries';
import { FetchExperience, ExperienceCookie } from 'core/ribbons/experiences';
import makeFetchEperience from 'infrastructure/experiences/fetchExperience';

export const useExperience = (fetchExperiences: FetchExperience, opts?: UseQueryOptions<ExperienceCookie>) => {
  const { pathname } = useLocation();
  return useQuery([EXPERIENCE, pathname], fetchExperiences, {
    ...opts,
  });
};

export default (opts?: UseQueryOptions<ExperienceCookie>) => {
  const fetchExperience = makeFetchEperience();
  return useExperience(fetchExperience, opts);
};
