import { Colorway } from '@uds/theme-utils';
import { DomainSearchSuggestionsAnalyticsProps } from 'presentation/components/ribbons/domain-search-results/components/DomainSearchSuggestions';

import { Layout } from './layout';

export interface Analytics {
  type: string;
  view: string;
  productId: string;
  promotion?: string;
}

export interface RibbonProps {
  colorway: Colorway;
  name: string;
  layout: Layout;
  loading: boolean;
  hBaseline?: number;
  id: string;
  flush?: Flush | boolean;
  duoLayout?: boolean;
  visible?: boolean;
  type?: Type;
  analytics?: Analytics | DomainSearchSuggestionsAnalyticsProps;
}

export enum Type {
  SKIPPED = 'RIBBON_SKIPPED',
  THIRD_PARTY = 'ThirdParty',
  ADS = 'Ads',
  CONTACT_FORM = 'ContactForm',
  ADVOCATE_TRUST_PILOT = 'AdvocateTrustPilot',
  AGGREGATE_RATING_TRUST_PILOT = 'AggregateRatingTrustPilot',
  ASPECTS = 'Aspects',
  BILLBOARD = 'Billboard',
  BILLBOARD_GENERIC = 'BillboardGeneric',
  BILLBOARD_PRODUCT = 'BillboardProduct',
  BILLBOARD_DOMAIN = 'BillboardDomain',
  BROADCAST = 'Broadcast',
  CENTERFOLD = 'Centerfold',
  CHANNELS = 'Channels',
  CUSTOMER_CASE_STUDY = 'CustomerCaseStudy',
  DOMAIN_SEARCH_RESULT_HERO = 'DomainSearchResultHero',
  DOMAIN_SEARCH_RESULTS = 'DomainSearchResults',
  DOMAIN_SEARCH_START = 'DomainSearchStart',
  DOMAIN_SEARCH_START_STRIP = 'DomainSearchStartStrip',
  DOMAIN_PROMO = 'DomainPromo',
  ELABORATE = 'Elaborate',
  INTRO = 'Intro',
  FILMSTRIP = 'Filmstrip',
  FREE_SPEECH = 'FreeSpeech',
  INFO = 'Info',
  INLINE_PRIVACY = 'InlinePrivacy',
  INLINE_PRIVACY_MANAGER = 'InlinePrivacyManager',
  LEGAL = 'Legal',
  LEGAL_DOCUMENT = 'LegalDocument',
  MESSAGE_STRIP = 'MessageStrip',
  META = 'Meta',
  MULTI_COMPARISON = 'MultiComparison',
  PILLARS = 'Pillars',
  PILLARS_RECUMBENT = 'PillarsRecumbent',
  POSTER = 'Poster',
  PRODUCT_CARDS = 'ProductCards',
  SALESBOARD = 'Salesboard',
  SALESBOARD_CLASSIC = 'SalesboardClassic',
  SHOWCASE = 'Showcase',
  TABLE = 'Table',
  TERMINATE_FOLD = 'TerminateFold',
  TIERS = 'Tiers',
  WEBSITE_REPORT_START = 'WebsiteReportStart',
}

export enum Fit {
  FULLSCREEN = 'fullscreen',
  FILL = 'fill',
  AUTO = 'auto',
}

export enum FetchType {
  PAGE_LANGUAGE = 'PAGE_LANGUAGE',
  RIBBONS_PREFETCHED = 'RIBBONS_PREFETCHED',
  RIBBON_FETCHED = 'RIBBON_FETCHED',
  RIBBONS_FETCHED = 'RIBBONS_FETCHED',
  ERRORED = 'ERRORED',
}

export enum Flush {
  LEADING = 'LEADING',
  TRAILING = 'TRAILING',
}
