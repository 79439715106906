import { ReactNode, createContext, useContext, useEffect, useReducer } from 'react';
import { invariant } from '@ux/js';
import { useGetQueryParam } from 'domain/selectors/common';
import { useLocation } from 'react-router';

export type DSTquery = {
  domainName: string;
  exactMatch: boolean;
  disableCtaButtons: boolean;
};

type Action =
  | {
      type: 'SET_QUERY';
      payload: { domainName: string };
    }
  | {
      type: 'TOGGLE_EXACT_MATCH';
    }
  | {
      type: 'DISABLE_CTA_BUTTONS';
    }
  | {
      type: 'ENABLE_CTA_BUTTONS';
    }
  | {
      type: 'RESET';
      payload: { domainName: string };
    };

export type DSTActionState = (query: Action) => void;

export const DSTContext = createContext<DSTquery | undefined>(undefined);
export const DSTActionContext = createContext<DSTActionState | undefined>(undefined);

type Props = {
  children: ReactNode;
};

function reducer(state: DSTquery, action: Action) {
  switch (action.type) {
    case 'SET_QUERY':
      return {
        ...state,
        domainName: action.payload.domainName,
        exactMatch: false,
      };
    case 'TOGGLE_EXACT_MATCH':
      return {
        ...state,
        exactMatch: !state.exactMatch,
      };
    case 'DISABLE_CTA_BUTTONS': {
      return {
        ...state,
        disableCtaButtons: true,
      };
    }
    case 'ENABLE_CTA_BUTTONS':
      return {
        ...state,
        disableCtaButtons: false,
      };
    case 'RESET':
      return {
        disableCtaButtons: false,
        domainName: action.payload.domainName,
        exactMatch: false,
      };
    default:
      return state;
  }
}
export const DSTProvider = ({ children }: Readonly<Props>) => {
  const initialQuery = useGetQueryParam('domain');
  const [query, dispatch] = useReducer(reducer, {
    disableCtaButtons: false,
    domainName: initialQuery ?? '',
    exactMatch: false,
  });
  const location = useLocation();

  useEffect(() => {
    // we need to reset the values whenever the user navigates away
    dispatch({ type: 'RESET', payload: { domainName: initialQuery ?? '' } });
  }, [location.pathname, initialQuery]);

  return (
    <DSTContext.Provider value={query}>
      <DSTActionContext.Provider value={dispatch}>{children}</DSTActionContext.Provider>
    </DSTContext.Provider>
  );
};

export const useDSTContext = () => {
  const context = useContext(DSTContext);
  invariant(context != null, 'useDSTContext must be used within a DSTProvider');
  return context;
};

export const useDSTActionContext = () => {
  const context = useContext(DSTActionContext);
  invariant(context != null, 'DSTActionContext must be used within a DSTProvider');
  return context;
};
