import {
  CreateDelayedQueue as CreateQueue,
  createDelayedQueue,
} from '@ux/queue';

interface Gtag {
  (...args: Array<any>): void;
}
interface GtagWindow extends Window {
  gtag: Gtag;
}

// checks whether GA is on the page
const createTest = (window: Window) => () => {
  const { gtag } = window as GtagWindow;
  if (typeof gtag === 'function') {
    return true;
  }
  return false;
};

// Sends analytics directly through GA4
const dispatch = (window: Window, createQueue: CreateQueue) => {
  const test = createTest(window);
  const action = (event: string, data: any) => {
    const { gtag } = window as GtagWindow;
    gtag('event', event, data);
  };

  return createQueue(action, test);
};

export const dispatchGA4Event = dispatch(window, createDelayedQueue)