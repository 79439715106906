import { getCookie, eraseCookie } from '@uds/cookies';

const cookieOptions = {
  path: '/',
};
const cookieClear = () => {
  const pwinteraction = getCookie('pwinteraction');
  // Fri Dec 10 2021 02:00:00 GMT+0200 (Eastern European Standard Time)
  // 1639094400000 ms
  const obsoleteTime = new Date('2021-12-10T00:00:00Z').getTime();

  let interactionTime: number;
  if (!pwinteraction) {
    return;
  }
  try {
    const timestamp = JSON.parse(pwinteraction).timestamp;
    interactionTime = new Date(timestamp).getTime();
    if (interactionTime < obsoleteTime || isNaN(interactionTime)) {
      eraseCookie('pwinteraction', cookieOptions);
      eraseCookie('OPTOUTMULTI', cookieOptions);
    }
  } catch (e) {
    // something wrong happened reading the cookies so they either
    // do not exist or are malformed, clear them anyway?
    eraseCookie('pwinteraction', cookieOptions);
    eraseCookie('OPTOUTMULTI', cookieOptions);
  }
};

export default cookieClear;
