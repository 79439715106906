import { useLocation, useHistory } from 'react-router';
import { useCallback, useMemo } from 'react';
import { useAppConfig, useUrl, useBrandConfig } from '@ux/whitelabel';
import { getCookie } from '@uds/cookies';

const useUSFConfig = () => {
  return useAppConfig('usf');
};

export const useExternalRoutes = () => {
  const appConfig = useUSFConfig();
  return appConfig?.externalRoutes ?? [];
};

export const useGetQueryParam = (param: string): string | null => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
};

type Props = {
  param: string;
  value: string;
};

export const useUpdateQueryParam = (): ((config: Props) => void) => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  return useCallback(
    ({ param, value }: Props) => {
      const params = new URLSearchParams(search);
      params.set(param, value);
      history.replace(`${pathname}?${params.toString()}`);
    },
    [search, pathname, history],
  );
};

export const useOrderProcessUrl = () => useUrl('orderProcess.index');

export const useTealiumCategories = () => {
  const brandConfig = useBrandConfig();
  const tealiumCategories = useMemo(() => {
    const categories = brandConfig?.analytics.tealium?.privacy?.categories ?? [];
    return categories.map((category: string) => (category === 'advertising' ? 'marketing' : category));
  }, [brandConfig?.analytics.tealium?.privacy?.categories]);

  return tealiumCategories;
};

export const usePrivacyPolicyUrl = () => {
  const url = useUrl('legal.privacy');

  return url ?? '/privacy-policy';
};

const isAbsoluteUrl = (url = '/') => {
  const absoluteUrlRegex = /^[a-z][a-z\d+.-]*:/;

  return absoluteUrlRegex.test(url);
};

export const useIsExternalRoute = (url: string | undefined): [boolean, string | undefined] => {
  const externalRoutes = useExternalRoutes();

  if (typeof url !== 'string') {
    return [false, url];
  }

  // if links to an email address or phone number, is external
  if (url.startsWith('tel:') || url.startsWith('mailto:')) {
    return [true, url];
  }

  const host = window.location.origin;
  const urlPathname = isAbsoluteUrl(url) ? new URL(url).pathname : url;

  const isExternalRoute = externalRoutes.some((externalRoute: string) => {
    const externalRoutePathname = isAbsoluteUrl(externalRoute) ? new URL(externalRoute).pathname : externalRoute;
    return urlPathname.startsWith(externalRoutePathname.replace(/\/$/, ''));
  });

  // if is included in external links, is external
  if (isExternalRoute) {
    return [true, url];
  }

  // if is abolute url and url origin is different than current location origin, is external
  if (isAbsoluteUrl(url) && !url.includes(host)) {
    return [true, url];
  }

  // if is abolute url and url origin is the same as current location origin, is internal
  if (isAbsoluteUrl(url) && url.includes(host)) {
    return [false, urlPathname];
  }

  return [false, url];
};

export const getLanguageCookie = () => getCookie('ux-language-preferred');
