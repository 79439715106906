import React from 'react';
import { css } from 'linaria';
import { WubLoader } from '@uds/penders';
import { useTheme } from '@uds/theme-utils';

const root = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  flex: 1;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
`;

const LoadPending = () => {
  const { theme } = useTheme();

  return (
    <div className={root}>
      <WubLoader color={theme.palette.highlight} />
    </div>
  );
};

export default LoadPending;
