import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isNumeric } from '@ux/js';
import { dispatchFBEvent, usePageView, dispatch, dispatchGAEvent } from '@uds/tagmg';
import { ExperienceCookie } from 'core/ribbons/experiences';
import useExperience from 'domain/selectors/useExperience';

export const usePageTracking = (pathname: string, experience: ExperienceCookie | undefined) => {
  usePageView();

  useEffect(() => {
    // eslint-disable-next-line camelcase
    dispatchFBEvent('ViewContent', { content_name: pathname });
  }, [pathname]);

  const reporting = experience?.experiment?.reporting;
  const id = reporting?.googleOptimizeExperimentId;
  const variant = reporting?.var;

  useEffect(() => {
    if (id != null && isNumeric(variant)) {
      dispatch('set', 'exp', `${id}.${variant}`);
      dispatchGAEvent('optimize');
    }
  }, [id, variant]);
};

export default function useUsePageTracking() {
  const { pathname } = useLocation();
  const experience = useExperience().data;
  return usePageTracking(pathname, experience);
}
