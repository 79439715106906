import { lazy, Suspense } from 'react';
import { useRibbons, normalizeRibbons } from 'domain/selectors/ribbons';
import useExperience from 'domain/selectors/useExperience';
import { useVisibilitySets } from 'presentation/context/VisibilitySets';
import usePage from 'domain/selectors/usePage';

import RibbonRaid from './RibbonRaid';
import LoadPending from '../pages/CMPage/LoadPending';
const LoadFailed = lazy(() => import('../pages/CMPage/LoadFailed'));

const ConnectedRibbonRaid = () => {
  const { isLoading, isError } = usePage();
  const ribbons = useRibbons();
  const visibilitySets = useVisibilitySets();
  const { data: experience } = useExperience();
  const normalizedRibbons = normalizeRibbons(ribbons, visibilitySets, experience);

  if (isError) {
    return (
      <Suspense fallback={<LoadPending />}>
        <LoadFailed />
      </Suspense>
    );
  }
  if (isLoading) {
    return <LoadPending />;
  }
  return <RibbonRaid ribbons={normalizedRibbons} />;
};

ConnectedRibbonRaid.displayName = 'ConnectedRibbonRaid';

export default ConnectedRibbonRaid;
