import React from 'react';
import { WubLoader } from '@uds/penders';
import { Colorway } from '@uds/theme-utils';
import { Layout } from 'domain/constants/layout';

import useStylesheet from './RibbonPending.stylesheet';

interface OuterProps {
  layout: Layout;
  colorway: Colorway;
}

const PureRibbonPending = ({ colorway, layout }: OuterProps) => {
  const { styles } = useStylesheet({ colorway, layout });

  return (
    <section style={styles.root}>
      <div style={styles.loader}>
        <WubLoader gu={5} color={styles.loader.color as string} />
      </div>
    </section>
  );
};

PureRibbonPending.displayName = 'RibbonPending';

export default PureRibbonPending;
