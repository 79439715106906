import { useEffect } from 'react';
import { useWindow } from '@uds/hooks';
import { usePagePath, usePageStatus } from 'domain/selectors/page';
import { usePageContext } from 'presentation/components/PageContext';

export const usePageStatusGuard = () => {
  const { pageUrl: currentPath } = usePageContext();
  const { history, window } = useWindow();
  const pageStatus = usePageStatus() ?? 200;
  const pagePath = usePagePath();

  useEffect(() => {
    // if the CMS returns us a path that's slightly different from the requested path
    // we want to update the URL to match. For example if we go to /en but the actual page
    // is /en/ - it's close enough not to 404 but we want the URL to be exact
    // we have to check the CMS's status code as we can get a 404 where the path is just empty
    // but we don't want to replace the URL in that case
    if (pageStatus === 301 && currentPath != null && pagePath !== currentPath) {
      // we don't want to do a connected-react-router replace as that would
      // trigger a page reload, we just want to update the url and carry on
      history.replaceState({}, '', currentPath);
    }

    // if the CMS returns a 404, we want to hard-redirect to the 404 page
    // we have to be explicit in the use of the trailing slash otherwise
    // when we've redirected, the CMS will once again tell us we're meant to be at /404/
    // this is a standard amongst brands, to have trailing slashes
    if (pageStatus === 404 && currentPath !== '/404/') {
      // note that we cannot use react-router push or replace hare
      // we need a hard reload for nginx to set the right status
      window.location.assign('/404/');
    }
  }, [currentPath, window, history, pageStatus, pagePath]);
};

export default usePageStatusGuard;
