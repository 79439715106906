/*
  interface Ribbon {
    type: String,
    props: {
      colorway: Colorway,
      layout: {
        ...
      },
      ...
    },
    ...
  }
  shrinkLayoutHolistic(
    schema: Ribbon, (ribbon we are parsing)
    idx: Number, (index of ribbon in ribbons object)
    ribbons: Ribbon[], (ribbons object schema is part of)
  );

  Returns the ribbon with 2 extra properties in layout
  shrinkLeading = true : no padding top
  shrinkLeading = false : padding top
  shrinkTrailing = true : no padding bottom
  shrinkTrailing = false : padding bottom

  Shrink trailing is applied if:
    next ribbon has the same colorway as the current one
    not last ribbon
    previous ribbon is part of top-collapsing list
    ribbon is part of the bottom-collapsing list
  Shrink leading is applied if:
    previous ribbon has the same colorway as the current one
    previous ribbon is part of bottom-collapsing list
    not first ribbon
    ribbon is part of the top-collapsing list
*/

import * as r from 'ramda';
import { Type } from 'domain/constants/ribbons';
import { Ribbon, Ribbons } from 'core/page';

import { getNextRibbon, getPreviousRibbon } from '../../RibbonRaid/utils';

export const bottomCollapsingRibbons = [Type.INTRO, Type.FREE_SPEECH];

export const topCollapsingRibbons = [
  Type.ADS,
  Type.CUSTOMER_CASE_STUDY,
  Type.DOMAIN_PROMO,
  Type.FILMSTRIP,
  Type.LEGAL,
  Type.LEGAL_DOCUMENT,
  Type.MULTI_COMPARISON,
  Type.PILLARS,
  Type.PILLARS_RECUMBENT,
  Type.PRODUCT_CARDS,
  Type.SALESBOARD_CLASSIC,
  Type.TABLE,
];

// check if 2 ribbons have the same colorway (if they have any)
const checkColorways = (ribbon1: Ribbon, ribbon2: Ribbon) => {
  if (ribbon1.props == null || ribbon2.props == null) {
    return false;
  }
  if (ribbon1.props.colorway == null || ribbon2.props.colorway == null) {
    return false;
  }
  // if there is a next component and both components have a colorway
  // then if the colorways are identical, shrinkTrailing is true
  return ribbon1.props.colorway === ribbon2.props.colorway;
};

export default (ribbon: Ribbon, idx: number, ribbons: Ribbons) => {
  return r.pipe(
    // add shrinkTrailing boolean to component
    r.ifElse(
      () => {
        const nextRibbon = getNextRibbon(idx, ribbons);

        // we don't apply shrinkTrailing to the last ribbon.
        if (nextRibbon == null) {
          return false;
        }
        const isCurrentBottomCollapsing = bottomCollapsingRibbons.indexOf(ribbon.type) > -1;
        const isNextTopCollapsing = topCollapsingRibbons.indexOf(nextRibbon.type) > -1;
        const isSameColorway = checkColorways(ribbon, nextRibbon);

        return isCurrentBottomCollapsing && isNextTopCollapsing && isSameColorway;
      },
      r.assocPath(['props', 'layout', 'shrinkTrailing'], true),
      r.assocPath(['props', 'layout', 'shrinkTrailing'], false),
    ),
    r.ifElse(
      () => {
        // we don't apply shrinkLeading to the first ribbon.
        const prevRibbon = getPreviousRibbon(idx, ribbons);
        if (prevRibbon == null) {
          return false;
        }
        const isCurrentTopCollapsing = topCollapsingRibbons.indexOf(ribbon.type) > -1;
        const isNextBottomCollapsing = bottomCollapsingRibbons.indexOf(prevRibbon.type) > -1;
        const isSameColorway = checkColorways(ribbon, prevRibbon);

        return isCurrentTopCollapsing && isNextBottomCollapsing && isSameColorway;
      },
      r.assocPath(['props', 'layout', 'shrinkLeading'], true),
      r.assocPath(['props', 'layout', 'shrinkLeading'], false),
    ),
  )(ribbon);
};
