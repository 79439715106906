import { getCookie } from '@uds/cookies';
import { FetchExperience } from 'core/ribbons/experiences';
import { EXPERIMENTS_COOKIE } from 'domain/constants/experiences';

export const makeFetchExperience = (): FetchExperience => {
  return () => {
    const cookie = getCookie(EXPERIMENTS_COOKIE);

    if (cookie == null) {
      return Promise.resolve(null);
    }

    try {
      const decodedCookie = decodeURIComponent(cookie);
      const experience = JSON.parse(decodedCookie) ?? {};
      return Promise.resolve(experience);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export default makeFetchExperience;
