import { Suspense, lazy } from 'react';
import ImpersonationRibbon from '@uds/impersonation-ribbon';
import { DropdownRouter } from 'react-dropdown-router';
import { ErrorBoundary } from 'react-error-boundary';
import { useAutoScroll } from '@uds/react-router-adapter';
import SalesHeader from 'presentation/components/SalesHeader';
import Footer from 'presentation/components/Footer';

import PrivacyModal from './PrivacyPolicy';
import Head from './Head';
import Core from './Core';
import PageProvider from './PageProvider';
import LoadPending from './pages/CMPage/LoadPending';
import { classNames } from './App.stylesheet';

const LoadFailed = lazy(() => import('./pages/CMPage/LoadFailed'));

const App = () => {
  // Auto scroll to top when pathname change
  useAutoScroll();

  return (
    <Suspense fallback={<LoadPending />}>
      <ImpersonationRibbon />
      <PrivacyModal />
      <DropdownRouter>
        <div className={classNames.root}>
          <SalesHeader />
          <main className={classNames.main}>
            <ErrorBoundary
              fallbackRender={() => (
                <Suspense fallback={<LoadPending />}>
                  <LoadFailed />
                </Suspense>
              )}
            >
              <Head />
              <PageProvider>
                <Core />
              </PageProvider>
            </ErrorBoundary>
          </main>
          <Footer />
        </div>
      </DropdownRouter>
    </Suspense>
  );
};

App.displayName = 'App';

export default App;
