import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';

const main = css`
  min-height: 300px;
  flex: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  background-color: ${cssTheme.palette.grey12};
`;

const root = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  font-family: ${cssTheme.font.content};
`;

export const classNames = {
  main,
  root,
};
