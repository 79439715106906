import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { invariant } from '@ux/js';
import { useLocales } from 'domain/selectors/ribbons';
import { useLocation, useHistory } from 'react-router';

interface PageState {
  pageUrl: string;
  locales: Array<string>;
}

interface PageActions {
  actions: {
    handleLanguageChange: (url: string, key?: string | number) => void;
  };
}

interface PageContextState extends PageState, PageActions {}

export const PageContext = createContext<PageContextState | undefined>(undefined);

type Props = {
  children: ReactNode;
};

/**
 * We need to sync up the pathname and the locales for usePage
 * If they do not change at the same time, we trigger two calls to fetch page.
 * First the language changes, then the url changes, or vice versa
 */
export const PageContextProvider = ({ children }: Props): JSX.Element => {
  const { push } = useHistory();
  const locales = useLocales();
  const filteredLocales = useMemo(() => locales.filter((locale) => locale !== ''), [locales]);
  const { pathname } = useLocation();
  const [state, setState] = useState<PageState>({
    pageUrl: pathname,
    locales: filteredLocales as Array<string>,
  });
  useEffect(() => {
    setState((state) => ({
      ...state,
      pageUrl: pathname,
    }));
  }, [pathname]);

  const handleLanguageChange = useCallback(
    (url, key) => {
      setState(({ locales }) => ({
        pageUrl: url,
        locales: Array.from(new Set([key, ...locales])),
      }));
      push(url);
    },
    [push],
  );

  const stateWithAction = useMemo(() => {
    return {
      ...state,
      actions: { handleLanguageChange },
    };
  }, [state, handleLanguageChange]);

  return <PageContext.Provider value={stateWithAction}>{children}</PageContext.Provider>;
};

export const usePageContext = (): PageContextState => {
  const context = useContext(PageContext);
  invariant(context != null, 'Context was undefined, did you forget to add The PageContextProvider?');
  return context;
};
