import { Colorway, cssTheme, Theme } from '@uds/theme-utils';
import color from 'color';
import { css } from 'linaria';

// Colorway general rules
// https://sketch.cloud/s/ZWdJY/p/c-colourway-general-rules

export const getTitleColor = (theme: Theme, against: Colorway | undefined) => {
  const { palette } = theme;
  switch (against) {
    case Colorway.LIGHT:
    case Colorway.NEUTRAL:
      return palette.grey02;
    case Colorway.DARK:
      return '#fff';
    case Colorway.PRIMARY:
    case Colorway.PRIMARY_GRADIENT:
      return palette.primaryContrast;
    case Colorway.HIGHLIGHT:
      return palette.highlightContrast;
    default:
      return palette.grey02;
  }
};

export const getBodyColor = (theme: Theme, colorway: Colorway | undefined) => {
  const { palette } = theme;
  switch (colorway) {
    case Colorway.LIGHT:
    case Colorway.NEUTRAL:
      return palette.grey04;
    case Colorway.DARK:
      return palette.grey11;
    case Colorway.PRIMARY:
    case Colorway.PRIMARY_GRADIENT:
      return color(palette.primaryContrast).alpha(0.85).string();
    case Colorway.HIGHLIGHT:
      return color(palette.highlightContrast).alpha(0.85).string();
    default:
      return palette.grey04;
  }
};
export const getBackgroundColor = (theme: Theme, colorway: Colorway | undefined) => {
  const { palette } = theme;
  switch (colorway) {
    case Colorway.LIGHT:
      return '#fff';
    case Colorway.NEUTRAL:
      return palette.grey12;
    case Colorway.DARK:
      return palette.grey02;
    case Colorway.PRIMARY:
    case Colorway.PRIMARY_GRADIENT:
      return palette.primary;
    case Colorway.HIGHLIGHT:
      return palette.highlight;
    default:
      return '#fff';
  }
};

export const getAgainst = (colorway?: Colorway) => {
  if (!colorway) {
    return Colorway.LIGHT;
  }
  if (Object.values(Colorway).includes(colorway)) {
    return colorway;
  }
  return Colorway.LIGHT;
};

export const getContrastColor = (theme: Theme, colorway: Colorway) => {
  const { palette } = theme;
  switch (colorway) {
    case Colorway.LIGHT:
    case Colorway.NEUTRAL:
      return palette.primary;
    case Colorway.DARK:
      return '#fff';
    case Colorway.PRIMARY:
      return palette.primaryContrast;
    case Colorway.PRIMARY_GRADIENT:
      return palette.primaryContrast;
    case Colorway.HIGHLIGHT:
      return palette.highlightContrast;
    default:
      return palette.primary;
  }
};

// linaria same as getContrastColor above, delete function after removing completely
export const contrastColor = css`
  color: ${cssTheme.palette.primary};
  &[data-colorway='DARK'] {
    color: '#fff';
  }
  &[data-colorway='PRIMARY'] {
    color: ${cssTheme.palette.primaryContrast};
  }
  &[data-colorway='PRIMARY_GRADIENT'] {
    color: ${cssTheme.palette.primaryContrast};
  }
  &[data-colorway='HIGHLIGHT'] {
    color: ${cssTheme.palette.highlightContrast};
  }
`;
