import { useSupportedLanguages } from '@ux/language';
import usePage from 'domain/selectors/usePage';
import { PageType } from 'domain/constants/page';

export const usePageType = () => {
  return usePage().data?.type;
};

export const usePageMeta = () => {
  return usePage().data?.payload?.meta;
};

export const usePageLanguages = () => {
  return usePageMeta()?.languages;
};

export const useCurrentLanguage = () => {
  return usePageMeta()?.language;
};

export const usePagePath = () => {
  return usePageMeta()?.path;
};

export const usePageStatus = () => {
  return usePageMeta()?.statusCode;
};

export const useIsPageLoading = () => {
  return usePage().isLoading;
};

export const useIsPageSuccess = () => {
  return usePage().isSuccess;
};

export const useIsPageError = () => {
  const pageType = usePageType();
  return usePage().isError || pageType === PageType.ERROR;
};

export const getLanguagesMap = (
  pageLanguages: { [key: string]: string } | undefined,
  supportedLanguages: Array<string>,
) => {
  if (pageLanguages == null) {
    return;
  }
  Object.keys(pageLanguages).forEach((key) => !supportedLanguages.includes(key) && delete pageLanguages[key]);
  return pageLanguages;
};

export const useLanguagesMap = () => {
  return getLanguagesMap(usePageLanguages(), useSupportedLanguages());
};
