import React, { lazy, Suspense, useMemo } from 'react';
import { useBreakpoints } from '@ux/responsive';
import { useHeaderHeightState } from '@uds/bookends';
import { splitRibbons } from 'domain/selectors/ribbons';
import { Ribbons } from 'core/page';

import StyledRoot from './RibbonRaid.stylesheet';
import RibbonSet from './RibbonSet';

const FaqJsonLd = lazy(() => import('../FaqJsonLd'));

interface Props {
  ribbons: Ribbons;
}

const RibbonRaid = ({ ribbons: allRibbons }: Props) => {
  const { isMinSm, isMinMd } = useBreakpoints();
  const [leadingHeightOffset] = useHeaderHeightState();

  const [leadingRibbons, ribbons] = useMemo(
    () =>
      splitRibbons({
        ribbons: allRibbons,
        isMinSm,
        isMinMd,
      }),
    [allRibbons, isMinSm, isMinMd],
  );

  const hasLeadingRibbons = leadingRibbons?.length > 0;
  return (
    <>
      <Suspense fallback={null}>
        <FaqJsonLd ribbons={allRibbons} />
      </Suspense>
      {hasLeadingRibbons && (
        <StyledRoot leadingHeightOffset={leadingHeightOffset}>
          <RibbonSet allRibbons={allRibbons} offset={0} ribbons={leadingRibbons} />
        </StyledRoot>
      )}
      <RibbonSet allRibbons={allRibbons} offset={leadingRibbons.length} ribbons={ribbons} />
      <div id="spaq-availability" />
    </>
  );
};

RibbonRaid.displayName = 'RibbonRaid';

export default RibbonRaid;
