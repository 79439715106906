import RibbonRaid from 'components/RibbonRaid';

import { DSTProvider } from './DSTContext';

const CMPage = () => {
  return (
    <DSTProvider>
      <RibbonRaid />
    </DSTProvider>
  );
};

export default CMPage;
