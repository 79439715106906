export const isObject = (value: any): value is Object => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const isEmpty = <T = unknown>(obj: T) => {
  if (obj === null) {
    return false;
  }
  if (obj === undefined) {
    return true;
  }
  if (obj === '') {
    return true;
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  return (
    isObject(obj) &&
    Object.entries(obj).length === 0 &&
    Object.getOwnPropertyNames(obj).length === 0 &&
    obj.constructor === Object
  );
};

export const isNilOrEmpty = <T extends any>(obj: T) => obj == null || isEmpty(obj);
export const isNotNilNorEmpty = <T>(value: T | null | undefined): value is T => !isNilOrEmpty(value);

export const range = (size: number, startAt: number = 0): Array<number> => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const omit = <T extends object = {}>(keys: Array<string>, obj: T): Partial<T> => {
  return (keys as any).reduce((a: Partial<T>, e: keyof T) => {
    const { [e]: omitted, ...rest } = a;
    return rest;
  }, obj);
};

export const isNotEmptyString = (value: string | undefined): boolean => {
  return value != null && value !== '';
};
