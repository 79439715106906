import { dispatchGAEvent } from '@uds/tagmg';
import App from 'presentation/components/AppProviders';
import { init as initApm } from '@elastic/apm-rum';
import cookieClear from 'presentation/utils/cookieClear';

import di from './di';
import render from './render';

// remove cookieClear december 10th 2022,
// had an incident where rum messed with the cookies
// related to privacy and set all categories to off,
// making clients not be able to see support chat
cookieClear();

dispatchGAEvent(
  'timing',
  // @ts-ignore: error after upgrading eslint config. Please replace with relevant comment
  'USF Critical Path',
  'start -> main bundle',
  Math.round(performance.now()),
);

const rootElement = document.getElementById('root');
declare const SERVICE_VERSION: string;
declare global {
  interface Window {
    APM_ENVIRONMENT: string;
  }
}
// If the root element already has children
// for example if it has been pre-rendered by Scout.
// Then we shouldn't try and render over the top.
// Instead we should just allow the html to be as is.
if (rootElement?.children.length === 0) {
  document.body.addEventListener('click', function (e) {
    try {
      let element = e.srcElement as Element;
      for (let i = 0; i < 5; i += 1) {
        const id = element.getAttribute('id');
        if (id == null) {
          // @ts-ignore: error after upgrading to ux-configs
          element = element.parentElement;
          continue;
        }

        dispatchGAEvent({
          hitType: 'event',
          eventCategory: 'Link Attribution',
          eventAction: 'click',
          eventLabel: id,
        });
        break;
      }
    } catch (ex) {
      // Silently fail just in case some browser doesn't like this.
    }
  });

  // in case someone does not update their .env
  const environment = window.APM_ENVIRONMENT || 'dev-private';

  initApm({
    serviceName: 'usf-ui',
    environment,
    serverUrl: '/api/apm',
    serviceVersion: SERVICE_VERSION,
    /* eslint-enable no-process-env */
  });
  di();
  render(App)();
}
