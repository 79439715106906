import { Fit } from 'domain/constants/ribbons';

export const mapFit = (fit: Fit | number, fill: boolean = false) => {
  if (fit === Fit.FULLSCREEN) {
    return {
      minHeight: '100vh',
    };
  }
  if (fit === Fit.AUTO) {
    return {
      height: 'auto',
    };
  }
  if (fit === Fit.FILL && fill) {
    return {
      flex: 1,
      flexBasis: 'auto',
    };
  }
  if (isFinite(fit as number)) {
    return {
      minHeight: fit,
    };
  }
  return {
    height: 'auto',
  };
};
