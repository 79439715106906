import { styled } from 'linaria/react';

interface RootProps {
  leadingHeightOffset: number;
}

export default styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${(props) => props.leadingHeightOffset}px);
`;
