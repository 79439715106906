import React from 'react';
import { dispatchGA4Event } from 'infrastructure/ga4';

export default (Component: React.FC<any>): React.FC<any> => {
    return function EnhancedComponent(props: any): any {
      const isButton = props?.role === 'button';
      const isDropdown = props?.role === 'option';
      const isTopNavLink = props?.isNavLink;
      const hasNavClickEvent = !isTopNavLink && !isButton && !isDropdown;
      const onClick = () => {
        dispatchGA4Event('nav_click', { location: 'Top nav > Low Nav' })

        if (props.onClick) {
          props.onClick();
        }
      };

      return (
        <Component
          {...props}
          // Send GA4 nav_click event only on sumbenu items
          {...hasNavClickEvent ? { onClick } : undefined}
        />
      );
    };
  };