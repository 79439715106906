import { useStyles } from '@team-griffin/stylr';
import { useBreakpoints } from '@uds/responsive';
import { gu2px, useTheme, Colorway } from '@uds/theme-utils';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import { getBackgroundColor, getTitleColor } from 'presentation/utils/colorway';
import { Layout } from 'domain/constants/layout';

import { mapFit } from './utils';

export default ({ colorway, layout }: { colorway: Colorway; layout: Layout }) => {
  const { theme } = useTheme();
  const { spacing } = theme;

  const activeFit = findResponsiveItem(useBreakpoints(), layout?.fit);

  return useStyles({
    default: {
      // @ts-ignore: error when upgrading to ux-configs
      root: {
        position: 'relative',
        backgroundColor: getBackgroundColor(theme, colorway),
        minHeight: gu2px(20, spacing),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        flexBasis: 'auto',
        ...mapFit(activeFit),
      },
      loader: {
        color: getTitleColor(theme, colorway),
        height: gu2px(5, spacing),
      },
    },
  });
};
