import { Http } from '@ux/drivers';
import { DispatchGAEvent, dispatchGAEvent } from '@uds/tagmg';
import { FetchPage } from 'core/page';
import jsonDriver from 'infrastructure/drivers/json';

let dispatchCriticalPath = function (dispatchEvent: DispatchGAEvent, now: number) {
  dispatchEvent({
    hitType: 'timing',
    timingCategory: 'USF Critical Path',
    timingVar: 'start -> ribbons finished',
    timingValue: Math.round(now),
  });
  // the GA event must be sent only once
  dispatchCriticalPath = function () {};
};

const makeFetchPage =
  (driver: Http = jsonDriver(), dispatchEvent: DispatchGAEvent = dispatchGAEvent): FetchPage =>
  async ({ pathname, locales = [], pageId, revision }) => {
    const getQuery = (): string => {
      const queryParams = new URLSearchParams();

      if (pathname != null) {
        queryParams.set('path', pathname);
      }
      if (revision != null) {
        queryParams.set('revision', revision);
      }
      if (pageId != null) {
        queryParams.set('pageId', pageId);
      }
      locales.forEach((locale, index) => {
        queryParams.set(`locales[${index}]`, locale);
      });

      return queryParams.toString();
    };

    const request = {
      url: `/api/app/v1/queries/fetch-page?${getQuery()}`,
    };

    const requestRttStart = performance.now();

    const result = await driver(request);
    /**
     * if the api sets an x-pex-no-cache header it means it's a dev with a no-cache cookie
     * so we don't want to bother sending any timing data to GA as it won't be representitive
     */
    if (!result.response?.headers?.get('x-pex-no-cache')) {
      const now = performance.now();
      const rttDiff = now - requestRttStart;

      dispatchCriticalPath(dispatchEvent, now);

      dispatchEvent({
        hitType: 'timing',
        timingCategory: 'USF RTT',
        timingVar: 'fetch page',
        timingValue: Math.round(rttDiff),
      });
    }
    return result?.body;
  };

export default makeFetchPage;
