import { SalesHeader as NavBar } from '@uds/bookends';
import { ReactRouterLink } from '@uds/react-router-adapter';
import { useLanguagesMap } from 'domain/selectors/page';
import { useExternalRoutes } from 'domain/selectors/common';

import { usePageContext } from '../../components/PageContext';
import withGA4Event from './withGA4Event';

export default function SalesHeader(): JSX.Element {
  const { actions } = usePageContext();
  const languages = useLanguagesMap();
  const languageMap = languages ?? {};
  const externalRoutes = useExternalRoutes();

  return (
    <NavBar
      onLanguageChange={actions.handleLanguageChange}
      LinkComponent={withGA4Event(ReactRouterLink)}
      externalRoutes={externalRoutes}
      languageMap={languageMap}
    />
  );
}
