import { useBrandConfig } from '@ux/whitelabel';
import { useLanguage } from '@ux/language';
import { useWindow } from '@uds/hooks';
import { isNilOrEmpty } from 'presentation/utils/generalUtils';
import { showBasedOnExperiences } from 'domain/selectors/ribbons/experiences';
import { Analytics, Type } from 'domain/constants/ribbons';
import { testVisibilitySet } from 'presentation/context/VisibilitySets';
import { Ribbon, Ribbons } from 'core/page';
import { ExperienceCookie } from 'core/ribbons/experiences';
import usePage from 'domain/selectors/usePage';
import { useMemo } from 'react';
import { Maybe } from 'core';

export const useLocales = () => {
  const brandConfig = useBrandConfig();
  const { data: language } = useLanguage();

  return useMemo(() => {
    return [language].concat(brandConfig.languages).filter((v, i, arr) => arr.indexOf(v) === i);
  }, [language, brandConfig.languages]);
};

export const useHomePageJSON = () => {
  const parseBrandConfig = (config: any) => {
    const legalName = config.details?.entityName;
    const social = config.social || {};
    const domain = config.domain;
    const logo = config.assets?.brandLogo;
    const serviceNumber = config.support?.phone;
    const givenAddress = config.details?.address || {};

    const sameAs = [];
    const address = {
      '@type': 'PostalAddress',
      'addressLocality': givenAddress.locality,
      'addressCountry': givenAddress.country,
      'postalCode': givenAddress.postCode,
      'addressRegion': givenAddress.region,
      'streetAddress': givenAddress.address1,
    } as {
      [key: string]: any;
    };
    /* eslint-disable */
    Object.keys(address).forEach((key) => address[key] === undefined && delete address[key]);

    social.facebook && sameAs.push(`https://www.facebook.com/${social.facebook}`);
    social.twitter && sameAs.push(`https://twitter.com/${social.twitter}`);
    social.linkedIn && sameAs.push(`https://www.linkedin.com/company/${social.linkedIn}`);
    social.youtube && sameAs.push(`https://www.youtube.com/user/${social.youtube}`);
    config.trustPilot?.pageId && sameAs.push(`https://www.trustpilot.com/review/${config.trustPilot.pageId}`);

    const description = config.details?.description;
    /* eslint-enable */

    return {
      '@id': `https://www.${domain}`,
      'url': `https://www.${domain}`,
      legalName,
      logo,
      address,
      description,
      'contactPoint': [
        {
          '@type': 'ContactPoint',
          'telephone': serviceNumber,
          'contactType': 'customer service',
        },
      ],
      sameAs,
    };
  };
  const brandConfig = useBrandConfig();
  const win = useWindow();
  // details does exist, we just added it.
  // we need to change how brandconfig type is made somehow
  // i just have no clue how
  const shouldShow =
    win.location.pathname === '/' &&
    // @ts-ignore: error after upgrading eslint config. Please replace with relevant comment
    !isNilOrEmpty(brandConfig.details) &&
    // @ts-ignore: error after upgrading eslint config. Please replace with relevant comment
    brandConfig.details.enableStructuredData;
  if (shouldShow) {
    const data = {
      '@context': 'http://schema.org/',
      '@type': 'Corporation',
      ...parseBrandConfig(brandConfig),
    };
    return data;
  }
  return null;
};

export const normalizeRibbons = (
  ribbons: Ribbons,
  activeVisibilitySets: { [key: string]: string } | undefined,
  experience: ExperienceCookie | undefined,
) => {
  const validRibbonTypes = Object.values(Type);

  return ribbons.reduce((acc, ribbon) => {
    const ribbonExperiences = ribbon.props?.experiences;
    const show = showBasedOnExperiences(ribbonExperiences, experience?.experiences ?? []);

    if (!show) {
      return acc;
    }

    const visibilitySet = ribbon.props?.visibilitySet ?? [];
    const visible = testVisibilitySet({ activeVisibilitySets, visibilitySet });
    const skipped = ribbon.props?.type === Type.SKIPPED;
    const unknown = validRibbonTypes.indexOf(ribbon.type as Type) < 0;

    return [
      ...acc,
      {
        ...ribbon,
        props: {
          ...ribbon.props,
          skipped,
          unknown,
          visible,
          hidden: skipped || unknown || !visible,
        },
      },
    ];
  }, []);
};

export const useRibbons = () => {
  const { data } = usePage();
  const ribbons = useMemo(() => data?.payload?.page ?? [], [data?.payload?.page]);
  return ribbons;
};

export const useRibbon = (id: string | undefined): Maybe<Ribbon> => {
  const ribbons = useRibbons();
  const ribbon = useMemo(() => ribbons.find((ribbon) => ribbon.id === id), [ribbons, id]);
  return ribbon;
};

export const useRibbonAnalytics = (id: string): Maybe<Analytics> => {
  const ribbon = useRibbon(id);
  const analytics = useMemo(() => ribbon?.props?.analytics, [ribbon]);
  return analytics;
};

export const splitRibbons = ({
  ribbons = [],
  isMinSm,
  isMinMd,
}: {
  ribbons: Array<any>;
  isMinSm: boolean;
  isMinMd: boolean;
}): [Array<any>, Array<any>] => {
  const i = ribbons.findIndex((ribbon) => {
    if (ribbon.type === Type.TERMINATE_FOLD) {
      if (isMinMd) {
        return ribbon.props.terminate.desktop;
      } else if (isMinSm) {
        return ribbon.props.terminate.tablet;
      }
      return ribbon.props.terminate.mobile;
    }
  });

  if (i < 0) {
    return [[], ribbons];
  }

  const leadingRibbons = ribbons.slice(0, i);
  const otherRibbons = ribbons.slice(i);
  return [leadingRibbons, otherRibbons];
};
