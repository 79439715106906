import { useEffect, useState } from 'react';
import { useDocument } from '@uds/hooks';
import { notifyScout as makeNotifyScout } from 'infrastructure/root/scout';
import { useIsPageSuccess, usePagePath, usePageStatus } from 'domain/selectors/page';

export const useNotifyScout = () => {
  const [notified, setNotified] = useState(false);
  const document = useDocument();
  const notifyScout = makeNotifyScout(document);
  const status = usePageStatus() ?? 200;
  const path = usePagePath();
  const isPageSuccess = useIsPageSuccess();

  useEffect(() => {
    if (isPageSuccess && !notified) {
      notifyScout({ status, path });
      setNotified(true);
    }
  }, [isPageSuccess, status, path, notified, notifyScout, setNotified]);
};

export default useNotifyScout;
