import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router';
import { useCloseDropdown } from 'react-dropdown-router';
import { ApmRoute } from '@elastic/apm-rum-react';
import ReactiveKnowledge from '@uds/reactive-knowledge';

import CMPage from './pages/CMPage';

const PureCore = () => {
  // whenever we change pages we should close all dropdowns
  const { pathname } = useLocation();
  const closeDropdown = useCloseDropdown();
  useEffect(closeDropdown, [pathname, closeDropdown]);

  return (
    <ReactiveKnowledge>
      <Switch>
        <ApmRoute path="/" exact={false} component={() => <CMPage />} />
      </Switch>
    </ReactiveKnowledge>
  );
};

export default PureCore;
