import { useQuery, UseQueryOptions } from 'react-query';
import { useLocation } from 'react-router';
import { useCurrency } from '@ux/currency';
import { useBrandConfig } from '@ux/whitelabel';
import { FetchPage, Page } from 'core/page';
import { PAGE } from 'domain/constants/queries';
import fetchPage from 'infrastructure/page/fetch';
import { usePageContext } from 'presentation/components/PageContext';

export const usePage = (fetch: FetchPage, opts?: UseQueryOptions<Page>) => {
  const { search } = useLocation();
  const currency = useCurrency();
  const ecommerce = useBrandConfig()?.ecommerce;
  const params = new URLSearchParams(search);
  const pageId = params.get('page_id');
  const revision = params.get('revision');
  const { pageUrl: pathname, locales } = usePageContext();
  const fn = () => fetch({ pathname, locales, pageId, revision });

  return useQuery([PAGE, pathname, currency, pageId, revision, locales], fn, {
    enabled: currency != null || ecommerce === false,
    refetchOnMount: false,
    // setting suspense to true is not a good idea, since this is used by the bookends
    // which means page navigation will unmount bookends
    suspense: false,
    ...opts,
  });
};

export default function useUsePage(opts?: UseQueryOptions<Page>) {
  return usePage(fetchPage(), opts);
}
