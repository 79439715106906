import React, { useMemo } from 'react';
import { useBreakpoints } from '@ux/responsive';
import { Ribbon as RibbonType, Ribbons } from 'core/page';

import shrinkLayoutHolistic from '../ribbons/holistics/shrinkLayoutHolistic';
import duoLayoutHolistic from '../ribbons/holistics/duoLayoutHolistic';
import Ribbon from './Ribbon';

interface OuterProps {
  offset: number;
  ribbons: Ribbons;
  allRibbons: Ribbons;
}

declare const index: number;
declare const ribbon: RibbonType;

const PureRibbonSet = ({ ribbons: baseRibbons, offset, allRibbons }: OuterProps) => {
  const { isMinMd } = useBreakpoints();
  const ribbons = useMemo(
    () =>
      baseRibbons.map((ribbon, index) => {
        const shrunkRibbon = shrinkLayoutHolistic(ribbon, offset + index, allRibbons);
        const duoRibbon = duoLayoutHolistic(shrunkRibbon, index, baseRibbons, isMinMd);
        return duoRibbon;
      }),
    [baseRibbons, offset, allRibbons, isMinMd],
  ) as Array<RibbonType>;
  return (
    <>
      <For each="ribbon" of={ribbons} index="index">
        <Ribbon key={ribbon.id} index={index} ribbon={ribbon} ribbons={ribbons} />
      </For>
    </>
  );
};

PureRibbonSet.displayName = 'RibbonSet';

export default PureRibbonSet;
