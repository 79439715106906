import React, { Suspense, lazy, useMemo } from 'react';
import { Ribbon, AnyRibbonProps } from 'core/page';

import { componentAdapterMap, importRibbon } from './utils';
import RibbonPending from '../ribbons/Ribbon/RibbonPending';

interface OuterProps {
  ribbon: Ribbon;
  ribbonProps: AnyRibbonProps;
  nextRibbon: Ribbon;
}

const PureDuoLayout = ({ ribbon, ribbonProps, nextRibbon }: OuterProps) => {
  const nextComponentAdapter = useMemo(() => componentAdapterMap[nextRibbon.type], [nextRibbon.type]);
  const nextRibbonProps = useMemo(
    () => nextComponentAdapter(nextRibbon),
    [nextRibbon, nextComponentAdapter],
  ) as AnyRibbonProps;

  const LazyComponent = useMemo(() => {
    return lazy(() => importRibbon(ribbon.type));
  }, [ribbon.type]);
  const NextLazyComponent = useMemo(() => {
    return lazy(() => importRibbon(nextRibbon.type));
  }, [nextRibbon.type]);

  const wrapperStyles = {
    display: 'flex',
    flex: 1,
    flexBasis: 'auto',
  };

  return (
    <Suspense
      fallback={
        <div style={wrapperStyles}>
          <RibbonPending {...ribbonProps} />
          <RibbonPending {...nextRibbonProps} />
        </div>
      }
    >
      <div style={wrapperStyles}>
        <LazyComponent key={ribbon.id} duoLayout={true} {...ribbonProps} id={ribbon.id} loading={!ribbon.ready} />
        <NextLazyComponent
          key={nextRibbon.id}
          duoLayout={true}
          {...nextRibbonProps}
          id={nextRibbon.id}
          loading={!nextRibbon.ready}
        />
      </div>
    </Suspense>
  );
};
PureDuoLayout.displayName = 'DuoLayout';

export default PureDuoLayout;
