import { ReactNode, memo } from 'react';
import useNotifyScout from 'application/actions/useNotifyScout';
import usePageStatusGuard from 'application/actions/usePageStatusGuard';
import usePageTracking from 'application/actions/usePageTracking';
import useSavePageLanguage from 'application/actions/useSavePageLanguage';
import { VisibilitySetsProvider } from 'presentation/context/VisibilitySets';
import { useRibbons } from 'domain/selectors/ribbons';

const MemoedVisibilitySetsProvider = memo(VisibilitySetsProvider);
export const PageProvider = ({ children }: { children: ReactNode }) => {
  usePageStatusGuard();
  usePageTracking();
  useNotifyScout();
  useSavePageLanguage();
  const ribbons = useRibbons();

  return <MemoedVisibilitySetsProvider ribbons={ribbons}>{children}</MemoedVisibilitySetsProvider>;
};

export default PageProvider;
