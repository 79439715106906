import React, { useMemo, lazy, Suspense, useEffect } from 'react';
import { RibbonProps, Type } from 'domain/constants/ribbons';
import { Ribbons, Ribbon } from 'core/page';

import {
  importRibbon,
  getNextRibbon,
  isDuoLayoutStart,
  isSkippedRibbon,
  isDuoLayoutEnd,
  getRibbonAdapter,
  isSupportedRibbon,
} from './utils';
import DuoLayout from './DuoLayout';
import RibbonPending from '../ribbons/Ribbon/RibbonPending';

interface OuterProps {
  ribbon: Ribbon;
  ribbons: Ribbons;
  index: number;
}

const PureRibbon = ({ ribbon, index, ribbons }: OuterProps) => {
  const componentAdapter = useMemo(() => getRibbonAdapter(ribbon.type), [ribbon.type]);
  const ribbonProps = useMemo(
    () => (componentAdapter ? componentAdapter(ribbon) : null),
    [componentAdapter, ribbon],
  ) as RibbonProps;

  const showPending = ribbon.type !== Type.META;
  const nextRibbon = useMemo(() => getNextRibbon(index, ribbons), [index, ribbons]);
  const LazyComponent = useMemo(() => {
    return lazy(() => importRibbon(ribbon.type));
  }, [ribbon.type]);
  useEffect(() => {
    if (isDuoLayoutStart(ribbon) || isDuoLayoutEnd(ribbon) || isSkippedRibbon(ribbon)) {
      return;
    }
    importRibbon(ribbon.type);
  }, [ribbon]);

  if (!isSupportedRibbon(ribbon.type)) {
    return null;
  }

  return (
    <Choose>
      <When condition={isDuoLayoutStart(ribbon)}>
        {/* @ts-ignore: error after upgrading to ux-configs */}
        <DuoLayout ribbon={ribbon} ribbonProps={ribbonProps} nextRibbon={nextRibbon} />
      </When>
      <When condition={isDuoLayoutEnd(ribbon)}>{null}</When>
      <When condition={isSkippedRibbon(ribbon)}>{null}</When>
      <Otherwise>
        <Suspense fallback={showPending && <RibbonPending {...ribbonProps} />}>
          <LazyComponent {...ribbonProps} id={ribbon.id} />
        </Suspense>
      </Otherwise>
    </Choose>
  );
};

PureRibbon.displayName = 'RibbonRaidRibbon';

export default PureRibbon;
