import { useSaveLanguage, useLanguage } from '@ux/language';
import { useEffect } from 'react';
import { useIsPageSuccess, useCurrentLanguage } from 'domain/selectors/page';

export const useSavePageLanguage = () => {
  const { mutate } = useSaveLanguage();
  const { data: preferred } = useLanguage();
  const isPageSuccess = useIsPageSuccess();
  const language = useCurrentLanguage();

  useEffect(() => {
    if (isPageSuccess && language != null && language !== preferred) {
      mutate({ language });
    }
  }, [isPageSuccess, preferred, language, mutate]);
};

export default useSavePageLanguage;
