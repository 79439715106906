import { after } from '@ux/promise';
import { NotifyScout } from 'core/root';

export const notifyScout =
  (document: Document): NotifyScout =>
  ({ status, path }) =>
    after(1000).then(() => {
      const url = path != null ? `${window.location.protocol}//${window.location.host}${path}` : path;
      const event = new CustomEvent('@scout/done', {
        detail: {
          statusCode: status,
          url,
        },
      });
      document.dispatchEvent(event);
      // scout doesn't always pick up on this event
      // especially when usf auto-redirects when fetching
      // so we also log to the console and scout will check the console
      // eslint-disable-next-line no-console
      console.log(`@scout/done/${status}`);
    });
